.wrapper {
    width: 100%;
    max-width: 1240px;
    margin: auto;
    padding: 40px 20px;
    box-sizing: border-box;
}

.wrapper > h2 {
    color: #8C52FE;
    font-weight: 700;
}

.wrapper > p {
    color: white;
}

.Main_Gaming_Box {
    width: 100%;
    height: 100%;
}

.MGB-Frame {
    width: 100%;
    height: 600px;
    border-radius: 10px;
    border: 2px solid #8C52FE;
    margin-top: 15px;
    margin-bottom: 15px;
}

.MGB-Frame> iframe {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.Sec_Blog {
    width: 100%;
    height: 100%;
}

.SB-Blog > div > img {
    width: 300px;
    height: 300px;
    border-radius: 10px;
    border: 2px solid #8C52FE;
}

.SB-Blog > div {
    padding: 5px;
}

.SB-Blog {
    display: flex;
    border-radius: 10px;
    border: 2px solid #8C52FE;
    margin: 10px 0;
}

.Play_Btn {
    width: 150px;
    padding: 10px 0;
    font-size: 20px;
    display: block;
    color: white;
    text-decoration: none;
    text-align: center;
    background-color: #8C52FE;
    border-radius: 10px;
}

.carousel-status,
.control-dots {
    display: none;
}