.Justify-End {
    justify-content: flex-end;
}

.nav-item>a {
    color: #8C52FE !important;
    font-size: 20px;
    transition: 0.5s;
}

.nav-item>a:hover {
    color: #8b52fe9c !important;
    font-size: 20px;
    text-shadow: 2px 1px 1px #8C52FE;
    transition: 0.5s;
}

.Navbar_Style {
    border-radius: 0 0 10px 10px;
    background: #ffffffe9;
    backdrop-filter: blur(30px);
    position: sticky;
    z-index: 999;
    top: 0;
}

.IB-Main {
    margin-top: 20px;
    margin-bottom: 20px;
}

/* .PlayGame_Box {
    margin-top: 15px;
} */

.Main-Slider_Mar {
    margin-bottom: 16px;
}

.PlayGame_Box>h2 {
    margin-left: 15px;
    margin-bottom: 0;
    color: #8C52FE;
    font-weight: 700;
    font-size: 45px;
}

.PlayGame_Box>h2>img {
    margin-top: -7px;
}

.GB-Grid>a {
    text-decoration: none;
    color: white;
}

.GB-Grid {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.GB-Grid>a {
    width: 210px;
    height: 210px;
    margin: 10px;
    border-radius: 10px;
    border: 2px solid transparent;
    transition: 0.5s;
}

.Hover_Image_Name {
    cursor: context-menu;
}

.Hover_Image_Name img {
    width: 55px;
    height: 55px;
    transition: all 0.2s;
}

.GB-Grid>a:hover {
    border: 2px solid #8C52FE;
    margin-top: 0px;
    transition: 0.5s;
}

.GB-Grid>a>div {
    width: 100%;
    height: 100%;
}

.GB-Grid>a>div>img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.GB-Name>p {
    margin-top: -24px;
    z-index: 9;
    border-radius: 0 0 8px 8px;
    position: relative;
    text-align: center;
    color: transparent;
    background-color: transparent;
    transition: 0.5s;
}

.GB-Grid a:hover div div p {
    color: #8C52FE;
    font-weight: 700;
    background: black;
    transition: 0.5s;
}

.footer-section {
    background: #ffffffe9;
    backdrop-filter: blur(30px);
    border-top: 2px solid #8C52FE;
    border-left: 2px solid #8C52FE;
    border-right: 2px solid #8C52FE;
    position: relative;
    border-radius: 10px 10px 0 0;
}

.footer-cta {
    border-bottom: 1px solid #373636;
}

.copyright-area {
    padding: 15px 0;
    margin-top: 10px;
    border-radius: 10px 10px 0 0;
}

.copyright-text p {
    margin: 0;
    font-size: 15px;
    color: #565656;
}

.copyright-text p a {
    color: #8C52FE;
}

.footer-menu li {
    display: inline-block;
    margin-left: 20px;
}

.footer-menu li:hover a {
    color: #8C52FE;
}

.footer-menu li a {
    font-size: 14px;
    color: #565656;
}

.Nav-Logo:hover img {
    animation: shake 0.5s;
    animation-iteration-count: infinite;
    cursor: none;
}

@media (max-width: 550px) {
    .Nav-Logo>img {
        animation: shake 0.5s;
        animation-iteration-count: infinite;
        cursor: none;
    }
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.GB-GamePartNew1::after {
    content: 'New Game';
    color: white;
    background-color: red;
    position: relative;
    margin-left: 1px;
    z-index: 99;
    top: -106.8%;
    padding: 7px 5px;
    border-radius: 10px 0 0 0;
    font-size: 13px;
}

.GB-GamePartHot1::after {
    content: 'Hot Game';
    color: white;
    background-color: red;
    position: relative;
    margin-left: 0px;
    z-index: 99;
    top: -106.8%;
    padding: 7px 5px;
    border-radius: 10px 0 0 0;
    font-size: 13px;
}

.GB-GamePartNew2::after {
    content: 'New Game';
    color: white;
    background-color: red;
    position: relative;
    margin-left: 0px;
    z-index: 99;
    top: -106.8%;
    padding: 7px 5px;
    border-radius: 10px 0 0 0;
    font-size: 13px;
}

.GB-GamePartHot2::after {
    content: 'Hot Game';
    color: white;
    background-color: red;
    position: relative;
    margin-left: 1px;
    z-index: 99;
    top: -106.8%;
    padding: 7px 5px;
    border-radius: 10px 0 0 0;
    font-size: 13px;
}

.h600 {
    height: 600px;
    margin-top: 15px;
    border-radius: 10px;
}

.thumbs-wrapper {
    display: none;
}

.slide {
    height: 600px;
}

.slide>img {
    height: 100%;
    border-radius: 10px;
}

.Main-Slider_Mar {
    margin-top: 15px;
}

a[rel~=nofollow] {
    color: #553df4
}

.visible {
    display: block
}

.hidden {
    display: none
}

.page {
    width: 100%
}

.container {
    position: relative;
    width: 90%;
    max-width: 1024px;
    margin: 0 auto
}

.header {
    padding: 16px 0
}

.header .title {
    font-size: 40px;
    line-height: 60px;
    font-weight: 700;
    margin: 0
}

.translations-list-container {
    padding-bottom: 8px;
    margin: 0 0 16px
}

.translations-list-container .translations-list {
    margin: 0;
    padding: 0;
    list-style: none
}

.translations-list-container .translations-list .translations-list-item {
    display: inline-block;
    padding: 0;
    margin: 0 8px 8px 0;
    font-weight: 700;
    color: #553df4
}

.translations-list-container .translations-list .translations-list-item a {
    display: inline-block;
    color: #553df4;
    border: 1px solid #553df4;
    border-radius: 32px;
    padding: 4px 16px
}

.translations-content-container {
    padding-top: 16px;
    border-top: 1px solid #eee
}

.footer {
    border-top: 1px solid #eee;
    margin: 32px 0 0;
    padding: 16px 0
}

.Message-Conntect>a {
    background: rgb(85, 61, 244);
    color: white;
    position: sticky;
    bottom: 20%;
    right: 0;
    z-index: 9;
    padding: 5px 0;
    border-radius: 10px;
    width: 40px;
    text-align: center;
    font-size: 20px;
    display: block;
}

.IB-Section {
    display: flex;
    gap: 15px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.IB-Section>a {
    text-decoration: none;
}

.IB-Section>a>div {
    background-color: #8b52fe3e;
    color: white;
    padding-top: 13px;
    padding-bottom: 13px;
    text-align: center;
    width: 128px;
    border-radius: 10px;
    border: 2px solid #8C52FE;
    transition: 0.5s;
}

.IB-Section a:hover>div {
    background-color: #8b52fe83;
    transition: 0.5s;
}

.IB-Column-1>img {
    width: 35px;
}

.IB-Column-2 {
    margin-top: 5px;
}






.row>* {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.page_404 {
    height: 100%;
    padding: 40px 0;
    background: #fff;
    font-family: monospace;
    border-radius: 10px;
    color: #000000 !important;
    border: 2px solid #8C52FE;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
}

.page_404 img {
    width: 100%;
}

.four_zero_four_bg {
    background: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif) no-repeat center center;
    height: 400px;
    background-position: center;
}


.four_zero_four_bg h1 {
    font-size: 80px;
    font-family: monospace;
}

.four_zero_four_bg h3 {
    font-size: 80px;
    font-family: monospace;
}

.link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #8C52FE;
    border-radius: 10px;
    text-decoration: none;
    margin: 20px 0;
    display: inline-block;
    font-family: monospace;
}

.contant_box_404 {
    margin-top: -50px;
}

.contant_box_404>div>img {
    width: 200px;
    margin-bottom: 10px;
}

@media (max-width: 700px) {
    .respons_center {
        margin-top: -70px;
    }

    .page_404 {
        overflow-x: hidden !important;
        overflow-y: hidden !important;
    }
}

.All-Mapping {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 10px 0;
    height: 100%;
}

.All-Mappinghyper {
    text-decoration: none;
    color: white;
    display: block;
    width: 19.3%;
    height: 430px;
    margin: 5px 0;
    transition: all 0.5s;
}

.All-Mappinghyper:hover {
    transition: all 0.2s;
}

.movie-img {
    height: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    border: 2px solid #8C52FE;
}

.movie-img:hover {
    box-shadow: #8C52FE 0px 2px 15px;
}
.movie-imgs {
    width: 100%;
    height: 400px;
    border-radius: 8px 8px 0 0;
}

.movie-img>div>h6 {
    margin: 0;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;
}

.movie-img:hover div h6 {
    color: #8C52FE;
    font-weight: 800;
}

.preloader {
    height: 100vh;
    width: 100%;
    background: #000;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.preloader .texts-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    width: 280px;
    font-size: 20px;
    font-weight: 800;
    overflow: hidden;
    color: #fff;
}

.modal-content {
    background: #ffffff96 !important;
}

.modal-title,
.modal-body {
    background: transparent !important;
    color: black !important;
}

.Search-Input {
    background-color: #ffffff96;
    color: black !important;
    border: 2px solid #8C52FE;
    outline: none;
    width: 100%;
    height: 100%;
    font-size: 17px;
    border-radius: 7px 7px 0px 0px;
    padding: 5px;
}

.nav-btn {
    color: #8C52FE !important;
    font-size: 20px;
    transition: 0.5s;
}

.nav-btn:hover {
    color: #8b52fe9c !important;
    text-shadow: 2px 1px 1px #8C52FE;
    font-size: 20px;
    transition: 0.5s;
    background: transparent !important;
}

.BackgroundImage {
    background: #101010 url("../Assets/Loading-Gif.gif") no-repeat center;
    background-size: 90%;
}

@media (max-width: 1200px) {
    .All-Mappinghyper {
        width: 24.3%;
    }
}

@media (max-width: 876px) {
    .All-Mappinghyper {
        width: 32.3%;
    }
}

@media (max-width: 700px) {
    .All-Mappinghyper {
        width: 32.3%;
        height: 400px;
    }

    .movie-imgs {
        height: 370px;
    }
}

@media (max-width: 700px) {
    .All-Mappinghyper {
        width: 49.3%;
        height: 500px;
    }

    .movie-imgs {
        height: 470px;
    }
}

@media (max-width: 520px) {
    .All-Mappinghyper {
        width: 49.3%;
        height: 400px;
    }

    .movie-imgs {
        height: 370px;
    }

    .movie-img>div>h6 {
        font-size: 12px;
    }
}

@media (max-width: 450px) {
    .All-Mappinghyper {
        width: 49.3%;
        height: 300px;
    }

    .movie-imgs {
        height: 270px;
    }
}

.Search-Result {
    background-color: #ffffff96;
    padding: 8px;
    border-radius: 0px 0px 7px 7px;
    margin-top: 5px;
    border: 2px solid #8C52FE;
}

.Search-Link {
    text-decoration: none;
    color: black;
    transition: all 0.5s;
    border-left: 3px solid transparent;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    display: block;
    margin-block: 3px;
    opacity: 0.55;
}

.Search-Link:hover {
    padding-left: 7px;
    border-left: 3px solid black;
    opacity: 1;
}

.modal-header {
    border-bottom: 2px solid #8C52FE;
}

.modal-content {
    border: 2px solid #8C52FE;
}

.btn-close:focus {
    outline: none;
    box-shadow: none;
}

.btn-close {
    opacity: 0.75 !important;
    border: 2px solid black;
}

.btn-close:hover {
    opacity: 1 !important;
}