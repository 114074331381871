@media (max-width: 1401px) {
    .GB-Grid>a {
        width: 205px;
        height: 205px;
    }
}

@media (max-width: 1371px) {
    .GB-Grid>a {
        width: 200px;
        height: 200px;
    }
}

@media (max-width: 1341px) {
    .GB-Grid>a {
        width: 240px;
        height: 240px;
    }
}

@media (max-width: 1321px) {
    .GB-Grid>a {
        width: 235px;
        height: 235px;
    }
}

@media (max-width: 1296px) {
    .GB-Grid>a {
        width: 230px;
        height: 230px;
    }
}

@media (max-width: 1271px) {
    .GB-Grid>a {
        width: 225px;
        height: 225px;
    }
}

@media (max-width: 1246px) {
    .GB-Grid>a {
        width: 220px;
        height: 220px;
    }
}

@media (max-width: 1221px) {
    .GB-Grid>a {
        width: 215px;
        height: 215px;
    }
}

@media (max-width: 1196px) {
    .GB-Grid>a {
        width: 210px;
        height: 210px;
    }
}

@media (max-width: 1171px) {
    .GB-Grid>a {
        width: 205px;
        height: 205px;
    }
}

@media (max-width: 1146px) {
    .GB-Grid>a {
        width: 200px;
        height: 200px;
    }
}

@media (max-width: 1121px) {
    .GB-Grid>a {
        width: 250px;
        height: 250px;
    }
}

@media (max-width: 1101px) {
    .GB-Grid>a {
        width: 245px;
        height: 245px;
    }
}

@media (max-width: 1080px) {
    .GB-Grid>a {
        width: 240px;
        height: 240px;
    }
}

@media (max-width: 1060px) {
    .GB-Grid>a {
        width: 235px;
        height: 235px;
    }
}

@media (max-width: 1040px) {
    .GB-Grid>a {
        width: 230px;
        height: 230px;
    }
}

@media (max-width: 1020px) {
    .GB-Grid>a {
        width: 225px;
        height: 225px;
    }
}

@media (max-width: 1000px) {
    .GB-Grid>a {
        width: 220px;
        height: 220px;
    }
}

@media (max-width: 980px) {
    .GB-Grid>a {
        width: 215px;
        height: 215px;
    }
}

@media (max-width: 960px) {
    .GB-Grid>a {
        width: 210px;
        height: 210px;
    }
}

@media (max-width: 940px) {
    .GB-Grid>a {
        width: 205px;
        height: 205px;
    }
}

@media (max-width: 920px) {
    .GB-Grid>a {
        width: 200px;
        height: 200px;
    }
}

@media (max-width: 900px) {
    .GB-Grid>a {
        width: 270px;
        height: 270px;
    }

    .GB-GamePartNew1::after {
        display: none;
    }

    .GB-GamePartHot1::after {
        display: none;
    }

    .GB-GamePartNew2::after {
        display: none;
    }

    .GB-GamePartHot2::after {
        display: none;
    }
}

@media (max-width: 890px) {
    .GB-Grid>a {
        width: 265px;
        height: 265px;
    }
}

@media (max-width: 875px) {
    .GB-Grid>a {
        width: 260px;
        height: 260px;
    }
}

@media (max-width: 860px) {
    .GB-Grid>a {
        width: 255px;
        height: 255px;
    }
}

@media (max-width: 845px) {
    .GB-Grid>a {
        width: 250px;
        height: 250px;
    }
}

@media (max-width: 830px) {
    .GB-Grid>a {
        width: 245px;
        height: 245px;
    }
}

@media (max-width: 815px) {
    .GB-Grid>a {
        width: 240px;
        height: 240px;
    }
}

@media (max-width: 800px) {
    .GB-Grid>a {
        width: 235px;
        height: 235px;
    }
}

@media (max-width: 785px) {
    .GB-Grid>a {
        width: 230px;
        height: 230px;
    }
}

@media (max-width: 770px) {
    .GB-Grid>a {
        width: 225px;
        height: 225px;
    }
}

@media (max-width: 755px) {
    .GB-Grid>a {
        width: 220px;
        height: 220px;
    }
}

@media (max-width: 740px) {
    .GB-Grid>a {
        width: 215px;
        height: 215px;
    }
}

@media (max-width: 725px) {
    .GB-Grid>a {
        width: 210px;
        height: 210px;
    }
}

@media (max-width: 710px) {
    .GB-Grid>a {
        width: 205px;
        height: 205px;
    }
}

@media (max-width: 695px) {
    .GB-Grid>a {
        width: 200px;
        height: 200px;
    }
}

@media (max-width: 680px) {
    .GB-Grid>a {
        width: 195px;
        height: 195px;
    }
}

@media (max-width: 665px) {
    .GB-Grid>a {
        width: 190px;
        height: 190px;
    }
}

@media (max-width: 650px) {
    .GB-Grid>a {
        width: 185px;
        height: 185px;
    }
}

@media (max-width: 635px) {
    .GB-Grid>a {
        width: 180px;
        height: 180px;
    }
}

@media (max-width: 620px) {
    .GB-Grid>a {
        width: 275px;
        height: 275px;
    }
}

@media (max-width: 610px) {
    .GB-Grid>a {
        width: 270px;
        height: 270px;
    }
}

@media (max-width: 600px) {
    .GB-Grid>a {
        width: 265px;
        height: 265px;
    }
}

@media (max-width: 590px) {
    .GB-Grid>a {
        width: 260px;
        height: 260px;
    }
}

@media (max-width: 580px) {
    .GB-Grid>a {
        width: 255px;
        height: 255px;
    }
}

@media (max-width: 570px) {
    .GB-Grid>a {
        width: 250px;
        height: 250px;
    }
}

@media (max-width: 560px) {
    .GB-Grid>a {
        width: 245px;
        height: 245px;
    }
}

@media (max-width: 550px) {
    .GB-Grid>a {
        width: 240px;
        height: 240px;
    }
}

@media (max-width: 540px) {
    .GB-Grid>a {
        width: 235px;
        height: 235px;
    }
}

@media (max-width: 530px) {
    .GB-Grid>a {
        width: 230px;
        height: 230px;
    }
}

@media (max-width: 520px) {
    .GB-Grid>a {
        width: 225px;
        height: 225px;
    }
}

@media (max-width: 510px) {
    .GB-Grid>a {
        width: 220px;
        height: 220px;
    }
}

@media (max-width: 500px) {
    .GB-Grid>a {
        width: 215px;
        height: 215px;
    }
}

@media (max-width: 490px) {
    .GB-Grid>a {
        width: 210px;
        height: 210px;
    }
}

@media (max-width: 480px) {
    .GB-Grid>a {
        width: 205px;
        height: 205px;
    }
}

@media (max-width: 470px) {
    .GB-Grid>a {
        width: 200px;
        height: 200px;
    }
}

@media (max-width: 460px) {
    .GB-Grid>a {
        width: 195px;
        height: 195px;
    }
}

@media (max-width: 450px) {
    .GB-Grid>a {
        width: 190px;
        height: 190px;
    }
}

@media (max-width: 440px) {
    .GB-Grid>a {
        margin: 8px 5px;
    }
}

@media (max-width: 430px) {
    .GB-Grid>a {
        width: 185px;
        height: 185px;
    }
}

@media (max-width: 420px) {
    .GB-Grid>a {
        width: 180px;
        height: 180px;
    }
}

@media (max-width: 410px) {
    .GB-Grid>a {
        width: 175px;
        height: 175px;
    }
}

@media (max-width: 400px) {
    .GB-Grid>a {
        width: 170px;
        height: 170px;
    }
}

@media (max-width: 390px) {
    .GB-Grid>a {
        width: 165px;
        height: 165px;
    }
}

@media (max-width: 380px) {
    .GB-Grid>a {
        width: 160px;
        height: 160px;
    }
}

@media (max-width: 370px) {
    .GB-Grid>a {
        width: 155px;
        height: 155px;
    }
}

@media (max-width: 360px) {
    .GB-Grid>a {
        width: 315px;
        height: 315px;
    }
}

@media (max-width: 350px) {
    .GB-Grid>a {
        width: 310px;
        height: 310px;
    }
}

@media (max-width: 340px) {
    .GB-Grid>a {
        width: 305px;
        height: 305px;
    }
}

@media (max-width: 330px) {
    .GB-Grid>a {
        width: 300px;
        height: 300px;
    }
}

@media (max-width: 320px) {
    .GB-Grid>a {
        width: 295px;
        height: 295px;
    }
}

@media (max-width: 310px) {
    .GB-Grid>a {
        width: 290px;
        height: 290px;
    }
}

@media (max-width: 1121px) {
    .Res_Dn {
        display: none;
    }
}

@media (max-width: 900px) {
    .Res_Dn {
        display: block;
    }
}

@media (min-width: 768px) {
    .container, .container-md, .container-sm {
        max-width: 100% !important;
    }
}

.footer-copy-width-1 {
    width: 50%;
}

.footer-copy-width-2 {
    width: 50%;
}

@media (max-width: 880px) {
    .footer-copy-width-1 {
        width: 100%;
        text-align: center;
        float: none !important;
    }

    .footer-copy-width-2 {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .footer-copy-width-2>div>ul {
        padding: 0 !important;
    }
}

@media (max-width: 450px) {
    .copyright-text p {
        font-size: 12px;
    }

    .footer-menu li a {
        font-size: 13px;
    }

    .footer-copy-width-1 {
        padding: 0 !important;
    }
}

@media (max-width: 999px) {
    .slide {
        height: 500px;
    }
}

@media (max-width: 850px) {
    .slide {
        height: 400px;
    }
}

@media (max-width: 600px) {
    .slide {
        height: 300px;
    }
}

@media (max-width: 500px) {
    .slide {
        height: 250px;
    }
}

@media (max-width: 500px) {
    .slide {
        height: 250px;
    }
}

@media (max-width: 700px) {
    .SB-Blog {
        display: block;
        text-align: center;
    }

    .Play_Btn {
        width: 100%;
        margin-top: 15px;
    }
}

@media (max-width: 400px) {
    .SB-Blog>div>img {
        width: 100%;
    }
}

@media (max-width: 370px) {
    .SB-Blog>div>img {
        width: 100%;
        height: 260px;
    }
}

@media (max-width: 330px) {
    .SB-Blog>div>img {
        width: 100%;
        height: 220px;
    }
}

@media (max-width: 400px) {
    .copyright-text p {
        font-size: 10px;
    }

    .footer-menu li a {
        font-size: 11px;
    }

    .footer-menu li {
        display: inline-block;
        margin-left: 0px !important;
        margin: 0 7px;
    }
}

.GB-GamePartNew1::after,
.GB-GamePartNew2::after,
.GB-GamePartHot1::after,
.GB-GamePartHot2::after {
    display: none;
}

@media (max-width: 437px) {
    .IB-Section>a>div {
        width: 127px;
    }
}

@media (max-width: 432px) {
    .IB-Section>a>div {
        width: 126px;
    }
}

@media (max-width: 428px) {
    .IB-Section>a>div {
        width: 125px;
    }
}

@media (max-width: 425px) {
    .IB-Section>a>div {
        width: 124px;
    }
}

@media (max-width: 422px) {
    .IB-Section>a>div {
        width: 123px;
    }
}

@media (max-width: 419px) {
    .IB-Section>a>div {
        width: 122px;
    }
}

@media (max-width: 416px) {
    .IB-Section>a>div {
        width: 121px;
    }
}

@media (max-width: 413px) {
    .IB-Section>a>div {
        width: 120px;
    }
}

@media (max-width: 410px) {
    .IB-Section>a>div {
        width: 119px;
    }

    .IB-Column-2 {
        font-size: 14px;
    }
}

@media (max-width: 407px) {
    .IB-Section>a>div {
        width: 118px;
    }
}

@media (max-width: 404px) {
    .IB-Section>a>div {
        width: 117px;
    }
}

@media (max-width: 401px) {
    .IB-Section>a>div {
        width: 115px;
    }
}

@media (max-width: 398px) {
    .IB-Section>a>div {
        width: 114px;
    }
}

@media (max-width: 392px) {
    .IB-Section>a>div {
        width: 113px;
    }
}

@media (max-width: 389px) {
    .IB-Section>a>div {
        width: 112px;
    }
}

@media (max-width: 386px) {
    .IB-Section>a>div {
        padding-left: 5px;
    }

    .IB-Section {
        gap: 10px;
    }
}

@media (max-width: 376px) {
    .IB-Section>a>div {
        width: 112px;
    }

    .IB-Section {
        gap: 5px;
    }

    .IB-Section>a {
        margin-bottom: 5px;
    }
}

@media (max-width: 370px) {
    .IB-Section>a>div {
        width: 110px;
    }
}

@media (max-width: 361px) {
    .IB-Section>a>div {
        width: 107px;
    }
}

@media (max-width: 351px) {
    .IB-Section>a>div {
        width: 104px;
    }

    .IB-Column-2 {
        font-size: 12px;
    }

    .IB-Column-1>img {
        width: 30px;
    }
}

@media (max-width: 342px) {
    .IB-Section>a>div {
        width: 102px;
    }
}

@media (max-width: 336px) {
    .IB-Section>a>div {
        width: 100px;
    }
}


/*
.Res-Last-1,
.Res-Last-2,
.Res-Last-3,
.Res-Last-4,
.Res-Last-5
*/

@media (max-width: 1920px) {
    .Res-Last-1,
    .Res-Last-2,
    .Res-Last-3,
    .Res-Last-4,
    .Res-Last-5 {
        display: block;
    }
}

@media (max-width: 1440px) {

    .Res-Last-1,
    .Res-Last-2 {
        display: none;
    }
}

@media (max-width: 1291px) {

    .Res-Last-3,
    .Res-Last-4 {
        display: none;
    }
}

@media (max-width: 1148px) {

    .Res-Last-1,
    .Res-Last-2,
    .Res-Last-3,
    .Res-Last-4 {
        display: block;
    }
}

@media (max-width: 1005px) {

    .Res-Last-1,
    .Res-Last-2 {
        display: none;
    }
}

@media (max-width: 863px) {

    .Res-Last-1,
    .Res-Last-2 {
        display: block;
    }
}

@media (max-width: 576px) {

    .Res-Last-1,
    .Res-Last-2 {
        display: none;
    }
}