* {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
    font-family: "Arimo", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}

:root {
    --Bg-Image: url("../Assets/Bg-Image.jpg");
}

html {
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scrollbar-color: #8b52fea8 transparent;
    scrollbar-width: thin;
    background-color: black;
}

::selection {
    color: #F70104;
    background-color: transparent;
    transition: all 1s;
}

body {
    color: white;
    background: #000000;
    background-attachment: fixed;
    height: 100%;
    width: 100%;
}

.Wrapper {
    backdrop-filter: blur(10px);
    width: 100%;
    height: 100%;
}

.Container {
    width: 100%;
    max-width: 1440px;
    padding: 0px 10px;
    margin: 0 auto;
}